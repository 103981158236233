import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import * as styles from "./RescueProjects.module.scss"
import "./RescueProjects.scss"

const Clients = ({ strapiData}) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: false,
    dots: true,
    dotsClass: "mainSliderwebClients",
    slidesToShow: 2,
    arrows: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    infinite: false,
    speed: 400,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  const cardData = [
    {
      image:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478560_612724aa7c.png",
    },
    {
      image:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478560_612724aa7c.png",
    },
    {
      image:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478560_612724aa7c.png",
    },
    {
      image:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478560_612724aa7c.png",
    },
  ]

  return (
    <React.Fragment>
      <div className={`${styles.ClientsWeb}`}>
        <Container>
          <h2
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` ${styles.cardWrapper}`}>
            <Slider
              {...settings}
              className={`${styles.SliderWidth} mainSliderClientsWeb `}
              ref={slide1}
            >
              {cardData.map((e, i) => (
                <Col xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={`${styles.bottomOfCard}`}>
                      <div className={styles.clientImg}>
                        <img
                          // src={
                          //   e?.image1 && e?.image1[0]?.localFile?.publicURL
                          // }
                          // alt={e?.image1 && e?.image1[0]?.alternativeText}
                          src={e?.image}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.btn}>
                        View Project Details{" "}
                        <img
                          decoding="async"
                          loading="lazy"
                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_a1fe05c0b9.png"
                          style={{
                            height: "24px",
                            width: "24px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Clients
