// extracted by mini-css-extract-plugin
export var SliderWidth = "Humble-module--SliderWidth--06fd9";
export var card = "Humble-module--card--4bd67";
export var cardInner = "Humble-module--cardInner--fd7aa";
export var cardWrapper = "Humble-module--cardWrapper--00df8";
export var cmsIndustriesWeb = "Humble-module--cmsIndustriesWeb--cda1e";
export var content = "Humble-module--content--e1932";
export var description = "Humble-module--description--52c75";
export var heading = "Humble-module--heading--5c9ca";
export var imgBx = "Humble-module--imgBx--bf859";
export var nocodeIndustry = "Humble-module--nocodeIndustry--0df08";
export var packedIndustryContainer = "Humble-module--packedIndustryContainer--c173f";
export var sci = "Humble-module--sci--b31ab";
export var trailBg = "Humble-module--trailBg--85c36";