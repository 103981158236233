import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Bets.module.scss"

function Bets({ strapiData }) {
  return (
    <div className={styles.bets}>
      <Container>
        <h1
          className={`${styles.heading} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} className="d-flex gap-30">
              <div className={styles.card}>
                <div className={styles.center}>
                  <img
                    className={styles.tick}
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_mark_00461e2891.png"
                    alt="tick"
                    style={{ height: "26px", width: "26px" , marginRight:"24px"}}
                  />
                  <h3>{e?.title}</h3>
                </div>
                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Bets
