// extracted by mini-css-extract-plugin
export var ClientsWeb = "RescueProjects-module--ClientsWeb--c595c";
export var SliderWidth = "RescueProjects-module--SliderWidth--b47bd";
export var bottomOfCard = "RescueProjects-module--bottomOfCard--1b2b4";
export var btn = "RescueProjects-module--btn--0e0b0";
export var cards = "RescueProjects-module--cards--9cb07";
export var clientImg = "RescueProjects-module--clientImg--efa47";
export var consultHead = "RescueProjects-module--consultHead--ccff8";
export var heading = "RescueProjects-module--heading--547d2";
export var headingPremium = "RescueProjects-module--headingPremium--0a98c";
export var trailBg = "RescueProjects-module--trailBg--287b6";